import {ActivatedRouteSnapshot, Router, RouterStateSnapshot,} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthenticationService} from '../../../service/authentication.service';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store/app.reducers';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';

/**
 * Can be used as a rooter guard to prove whether the user is poi dev in any channel
 */
@Injectable()
export class DevGuard {

  constructor(private store: Store<fromRoot.AppState>, private router: Router) {
  }

  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.pipe(select(fromRoot.getUserState)).pipe(
      // filter out userState objects where userInfo is not yet set
      filter(userState => !!userState.userInfo),
      map(userState => {
        if (AuthenticationService.isDevPoi(userState)) {
          return true;
        } else {
          return false;
        }
      }));
  }
}
